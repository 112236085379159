import React, { Component, createContext } from "react";

export enum pages {
  home = "#",
  product = "product",
  technical = "technical",
  contact = "contact",
  privacy = "privacy-information",
  formSubmitted = "form-submitted",
  notFound = "404",
}

interface IState {
  currentPage: pages;
  setCurrentPage: (page: pages) => void;
}

interface IProps {}

export const PageContext = createContext<IState>({
  currentPage: pages.home,
  setCurrentPage: () => {},
});

export class PageProvider extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentPage: pages.home,
      setCurrentPage: this.setCurrentPage,
    };
  }

  setCurrentPage = (page: pages) => {
    this.setState((state) => ({ ...state, currentPage: page }));
  };

  render() {
    return (
      <PageContext.Provider value={{ ...this.state }}>{this.props.children}</PageContext.Provider>
    );
  }
}
