// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fda-update-tsx": () => import("./../../../src/pages/fda-update.tsx" /* webpackChunkName: "component---src-pages-fda-update-tsx" */),
  "component---src-pages-form-submitted-js": () => import("./../../../src/pages/form-submitted.js" /* webpackChunkName: "component---src-pages-form-submitted-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-information-js": () => import("./../../../src/pages/privacy-information.js" /* webpackChunkName: "component---src-pages-privacy-information-js" */),
  "component---src-pages-product-tsx": () => import("./../../../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-pages-technical-tsx": () => import("./../../../src/pages/technical.tsx" /* webpackChunkName: "component---src-pages-technical-tsx" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */)
}

