import React from "react";

// eslint-disable-next-line import/no-unresolved
import { PageProvider } from "./src/context/page-context";

export const wrapRootElement = ({ element }) => <PageProvider>{element}</PageProvider>;

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log("new pathname", location.pathname);
//   console.log("old pathname", prevLocation ? prevLocation.pathname : null);
// };
